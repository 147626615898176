import React, { CSSProperties } from 'react';
import LogoSvg from '../../../assets/img/wssNewLogo.png';
import './Logo.scss';


type Props = {
  src: string;
  width?: number | string;
  height?: number | string;
  alt?: string;
  style?: CSSProperties;
};

const Logo = ({ alt = 'hiii', height = 'auto', width = 'auto', src, style = {} }: Props) => {
  return (
    <div className='logo' style={{width:'220px',height:'220px',alignSelf:'center', padding:0}}>

<img src={LogoSvg} alt={alt} width={width} height={height} className='logo-img' />

      {/* <img src={src} alt={alt} width={width} height={height} className='logo-img' /> */}
    </div>
  );
};

export default Logo;
