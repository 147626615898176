import React, { useState, useEffect } from 'react';
import { Button, Layout, Row, notification } from 'antd';
import { Content, Header } from 'antd/lib/layout/layout';
import LogoNew from '../../assets/img/logonew.png';
import { Card, Space } from 'antd';
import { Form, Input } from 'antd';
import { DatePicker } from 'antd';
import { Select } from 'antd';
import { Col } from 'antd';
import MaskedInput from 'antd-mask-input';
import { useLocation } from 'react-router-dom';
import { history } from './../../redux/store';
import setting from '../../config/setting';
import axios from 'axios';
import { Affix } from 'antd';
import '../checkin/CheckinDetails.css'
import { date } from 'yup';

const { Option } = Select;

const containerStyle = {
  width: '100%',
  height: 1000,
  overflow: 'auto',
};

const style = {
  width: '100%',
  height: 1000,
};

const CheckinDetails = () => {
  const [form] = Form.useForm();

  const [container, setContainer] = React.useState(null);
  const headerStyle = {
    background: '#f0f0f0'
  };

  const logo = {
    height: '43px',
    display: 'block',
    margin: 'auto',
    marginTop: '10px'
  };

  const content = {
    background: 'white',
    color: '#002c8c',
    textAlign: 'center',
    // padding: '20px',
  };

  const heading = {
    margin: '10px 0px 0px 0px'
  };

  const location = useLocation();
  const { state } = location;
  const [company, setCompany] = useState('');
  const [first_name, setFirst_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [ein, setEin] = useState('');
  const [driver_license, setDriver_license] = useState('');
  const [ssn, setSSN] = useState('');
  const [dob, setDob] = useState('');
  const [reason, setReason] = useState('');
  const [empStatus, setEmpStatus] = useState('Pending');
  const [isButtonDisable, setIsButtonDisable] = useState(false);
  const [isRequired, setIsRequired] = useState(true);
  const [notes, setNotes] = useState('');
  const [isConditionMet, setIsConditionMet] = useState(false);
  // let inputCheckIn = location?.state?.dateInput;
  // let paramsCheckIn = location?.state?.dateParams;
  let currentDate = new Date();
  let paramsCheckIn = currentDate.toISOString().slice(0, 19).replace('T', ' '); 
  console.log("checkin time ::", paramsCheckIn);

  useEffect(() => {
    if (location && location.state == null) {
      history.goBack();
    }
  }, []);
  const handleEmpStatusChange = (value) => {
    setEmpStatus(value)
  }

  const validateFields = () => {
    console.log('valid first', ein, ssn, driver_license);
    return new Promise((resolve, reject) => {
      if (ein === '' && ssn === '' && driver_license === '') {
        console.log('Please fill one of them');
        setIsConditionMet(true);
        reject(); // Reject the promise if the condition is not met
      } else {
        setIsConditionMet(false);
        resolve(); // Resolve the promise if the condition is met
      }
    });
  };

  const reasons_array = [
    ['ANNUAL', 'ANNUAL'],
    ['AUDIOGRAM', 'AUDIOGRAM'],
    ['BLIND SAMPLE', 'BLIND SAMPLE'],
    ['COURT ORDERED', 'COURT ORDERED'],
    ['FITNESS FOR DUTY', 'FITNESS FOR DUTY'],
    ['FOLLOW-UP', 'FOLLOW-UP'],
    ['JOB TRANSFER', 'JOB TRANSFER'],
    ['POST-ACCIDENT', 'POST-ACCIDENT'],
    ['PRE-EMPLOYMENT', 'PRE-EMPLOYMENT'],
    ['PRE-SITE ACCESS', 'PRE-SITE ACCESS'],
    ['PROBATION', 'PROBATION'],
    ['PROMOTION', 'PROMOTION'],
    ['RANDOM', 'RANDOM'],
    ['RE-CERTIFICATION', 'RE-CERTIFICATION'],
    ['REASONABLE SUSPICION/CAUSE', 'REASONABLE SUSPICION/CAUSE'],
    ['RETURN TO DUTY', 'RETURN TO DUTY'],
    ['SWEEP', 'SWEEP'],
    ['Transfer Medicals', 'Transfer Medicals'],
    ['OTHER', 'OTHER'],
    ['CONTRACTUAL', 'CONTRACTUAL'],
    ['PERIODIC', 'PERIODIC']
  ];

  const handleReasonChange = (value) => {
    setReason(value);
    setIsRequired(value === '');
    // Apply custom CSS when option is selected
    const styleTag = document.createElement('style');
    styleTag.setAttribute('id', 'custom-css'); // Set an id to identify this style tag
    styleTag.innerHTML = `
      .ant-form-item-explain.ant-form-item-explain-error {
        display: ${value === '' ? 'block' : 'none'}; // Show error message only when required
        color: ${value === '' ? 'red' : 'white'}; // Change error color based on the selected option
      }
    `;

    // Check if the style tag already exists and remove it before adding the new one
    const existingStyleTag = document.getElementById('custom-css');
    if (existingStyleTag) {
      document.head.removeChild(existingStyleTag);
    }

    document.head.appendChild(styleTag);
  };

  const resetCustomStyles = () => {
    const existingStyleTag = document.getElementById('custom-css');
    if (existingStyleTag) {
      document.head.removeChild(existingStyleTag);
    }
  };


  const saveForm = async () => {
    // console.log("inside api method", dob)
    // return;
    setIsButtonDisable(true);
    await axios
      .post('https://empappdata.wss-corp.com/api/saveCheckIns', {
        params: {
          company: company,
          first_name: first_name,
          last_name: last_name,
          ein: ein,
          driver_license: driver_license,
          ssn: ssn,
          dob: dob,
          reasons: reason,
          check_in: paramsCheckIn,
          checkin_status: empStatus,
          checkin_notes: notes
        }
      })
      .then((respone) => {
        console.log(respone);
        if (respone && respone.status === 200) {
          if (respone.data == 'Update Test Result!!') {
            setTimeout(() => {
              history.push({
                pathname: '/public/check-in'
              });
            }, 600);
            setTimeout(() => {
              notification['success']({
                message: 'Request Sent Successfully !'
              });
            }, 100);
            setIsButtonDisable(false);
            setSSN('');
            setReason('');
          } else if (respone.data.error === 'Technical Issue') {
            setIsButtonDisable(false);
            notification['error']({
              message: 'Some Technical Error Occurred! Please try after some time !'
            });
          }
        }
      });
  };

  const onFinish = (values) => {
    console.log('form');
    validateFields().then(() => {
      form.resetFields();
      resetCustomStyles();
      saveForm();
    });
  };

  console.log(company, first_name, last_name, ein, driver_license, ssn, dob, reason, paramsCheckIn, 'params')
  const disabledDate = (current) => {
    // Disable dates greater than the current date
    return current && current > new Date();
  };

  return (
    <>
      <div style={containerStyle} ref={setContainer}>
        <Layout>
          <Header style={headerStyle}>
            <img style={logo} src={LogoNew} alt='logo' />
          </Header>
          <Content style={content}>
            <h3 style={heading}>Check In Details</h3>
            <div style={style}>
              <Space direction='horizontal' size={24}>
                <Card style={{ width: '100%' }}>
                  <Form
                    form={form}
                    onFinish={onFinish}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ maxWidth: 600 }}
                  >
                    <Form.Item
                      label='Company'
                      name='company'
                      rules={[
                        {
                          required: true,
                          message: 'This field is required !'
                        }
                      ]}
                    >
                      <Input value={company} onChange={(event) => setCompany(event.target.value)} />
                    </Form.Item>
                    <Form.Item label='Employee' name='Employee'>
                      <Row gutter={24}>
                        <Col className='gutter-row' span={12}>
                          <Form.Item
                            label='First Name'
                            name='first_name'
                            rules={[
                              {
                                required: true,
                                message: 'This field is required !'
                              }
                            ]}
                          >
                            <Input
                              value={first_name}
                              onChange={(event) => setFirst_name(event.target.value)}
                            />
                          </Form.Item>
                        </Col>
                        <Col className='gutter-row' span={12}>
                          <Form.Item
                            label='Last Name'
                            name='last_name'
                            rules={[
                              {
                                required: true,
                                message: 'This field is required !'
                              }
                            ]}
                          >
                            <Input
                              value={last_name}
                              onChange={(event) => setLast_name(event.target.value)}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item label="Please fill one of them" />
                    <Row gutter={24}>
                      <Col className='gutter-row' span={8}>
                        <Form.Item label='EIN' name='ein'>
                          <Input
                            value={ein}
                            onChange={(event) => {
                              setEin(event.target.value);
                              setIsConditionMet(false);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col className='gutter-row' span={8}>
                        <Form.Item label='Driver License' name='driver_license'>
                          <Input
                            value={driver_license}
                            onChange={(event) => {
                              setDriver_license(event.target.value);
                              setIsConditionMet(false);
                            }}

                          />
                        </Form.Item>
                      </Col>
                      <Col className='gutter-row' span={8}>
                        <Form.Item label='SSN' name='ssn'>
                          <MaskedInput
                            mask='111-11-1111'
                            value={ssn}
                            placeholder='SSN _ _ _/_ _/_ _ _ _'
                            onChange={(event) => {
                              setSSN(event.target.value);
                              setIsConditionMet(false);
                            }}
                            style={{ backgroundColor: 'white' }}
                          />
                        </Form.Item>
                      </Col>
                      {isConditionMet && (
                        <div style={{ color: 'red', marginTop: '0px', padding: '0px 12px' }}>
                          Please fill one of the following fields: EIN, Driver License, or SSN.
                        </div>
                      )}
                    </Row>
                    <Row gutter={24}>

                      {/* <Col className='gutter-row' span={12}>
                    <Form.Item
                      label='Date of Birth'
                      name='D.O.B'
                      rules={[
                        {
                          required: true,
                          message: 'This field is required !'
                        }
                      ]}
                      style={{ marginRight: '142px', rowGap: 2 }}
                    >
                      <DatePicker
                      
                        selected={dob}
                        
                        onChange={(date) => setDob(date)}
                         
                        format='MM/DD/YYYY'
                        disabledDate={disabledDate}
                        style={{ width: '270px' }}
                      />
                    </Form.Item>
        
                  </Col> */}
                    <Col className='gutter-row' span={12}>
                        <Form.Item
                          label='Reason'
                          name='reason'
                          rules={[
                            {
                              required: isRequired,
                              message: 'Please select an option',
                              style: {
                                display: isRequired ? 'block' : 'none' // Show error message only when required
                              }
                            }
                          ]}
                        >
                          <Space wrap>
                            <Select
                              value={reason}
                              style={{ width: 280 }}
                              onChange={(value) => handleReasonChange(value)}
                              // onChange={(value) => setReason(value)}

                              options={[
                                { value: '', label: 'Select Reason' }, // Default option
                                ...reasons_array.map((reason) => ({
                                  value: reason[0],
                                  label: reason[1]
                                }))
                              ]}
                            />
                          </Space>
                        </Form.Item>
                      </Col>

                      <Col className='gutter-row' span={12}>
                        <Form.Item
                          label='Date of Birth'
                          name='dob'
                          rules={[
                            {
                              required: true,
                              message: 'This field is required!',
                            },
                          ]}
                          style={{ marginRight: '142px', rowGap: 2 }}
                        >
                          <input
                            type='date'
                            value={dob}
                            onChange={(e) => setDob(e.target.value)} // Update state with the selected date
                            placeholder='MM/DD/YYYY'
                            style={{ width: '270px', padding: '5px', borderRadius:'13px',backgroundColor:'#EBEBEB',border:'none',padding:'8px' }}
                          />
                        </Form.Item>
                      </Col>

                     
                    </Row>
                    {/* <Row gutter={24}> */}
                    {/* <Col className='gutter-row' span={12}>
                    <Form.Item
                      label='Status'
                      name='status'
                      
                    >
                      <Space wrap>
                        <Select
                          value={empStatus}
                          style={{ width: 280 }}
                          onChange={(value) => handleEmpStatusChange(value)}
                          options={[
                            // { value: '', label: 'Select Status' }, // Default option
                            { value: 'pending', label: 'Pending' }, // Static option A
                            { value: 'completed', label: 'Completed'},  // Static option B
                            { value: 'archived', label: 'Archived' }  // Static option B

                          ]}
                        />
                      </Space>
                    </Form.Item>
                  </Col> */}
                    {/* <Col className='gutter-row' span={12}>
          <Form.Item
            label='Notes'
            name='notes'
          >
            <Input.TextArea 
              rows={3} 
              placeholder='Enter your notes here...' 
              style={{ width: 280 }}
              onChange={(e)=>setNotes(e.target.value)}
            />
          </Form.Item>
        </Col> */}

                    {/* </Row> */}
                    <Row span={24}>
                      {/* <Col className='gutter-row' span={12}>
                    <Form.Item label='Check In' name='checkin'>
                      <Input type='text' defaultValue={inputCheckIn} />
                    </Form.Item>
                  </Col> */}
                      <Col className='gutter-row mt-4' style={{ marginTop: 3, marginLeft: 65 }} span={18}>
                        <Affix target={() => container}>
                          <Button
                            type='primary'
                            htmlType='submit'
                            style={{ width: 100 }}
                            disabled={isButtonDisable}
                          // onClick={}
                          >
                            Submit
                          </Button>
                        </Affix>
                      </Col>
                    </Row>
                  </Form>
                </Card>
              </Space>
            </div>
          </Content>
        </Layout>
      </div>
    </>
  );
};

export default CheckinDetails;
