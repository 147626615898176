import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import NotFound from './pages/sessions/404';
import { sessionRoutes } from './routing';
import './App.scss';
import { useHideLoader } from './hooks/useHideLoader';

import { history } from './redux/store';

const Routes = ({ routes, layout = '' }) => (
  <Switch>
    {routes.map((route, index) => (
      <Route
        key={index}
        exact={route.exact}
        path={layout.length > 0 ? `/${layout}/${route.path}` : `/${route.path}\``}
        component={() => <route.component />}
      />
    ))}

    <Route path='*'>
      <Redirect to='/public/page-404' />
    </Route>
  </Switch>
);

const SessionRoutes = () => <Routes routes={sessionRoutes} layout='public' />;

const App = () => {
  useEffect(() => {
    setTimeout(() => history.push({ pathname: '/public/check-in/', state: {} }), 300);
  }, []);

  useHideLoader();

  return (
    <Switch>
      <Route path='/' exact>
        <Redirect to='/public/check-in/' />
      </Route>

      <Route path='/public'>
        <SessionRoutes />
      </Route>

      <Route path='*'>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default App;
