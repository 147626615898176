import { IRoute } from '../interfaces/routing';

import NotFound from '../pages/sessions/404';
import InternalError from '../pages/sessions/500';
import Checkin from '../pages/checkin/Checkin';
import CheckinDetails from '../pages/checkin/CheckinDetails';
export const sessionRoutes: IRoute[] = [
  {
    path: 'check-in',
    component: Checkin
  },
  {
    path: 'checkdetails',
    component: CheckinDetails
  },
  {
    path: 'page-404',
    component: NotFound
  },
  {
    path: 'page-500',
    component: InternalError
  }
];
