import React, { ReactNode } from 'react';
import './Public.scss';
import Logo from '../components/logo/Logo';
import LogoSvg from '../../assets/img/logo.svg';

type Props = { children: ReactNode; bgImg?: string; transparent?: boolean };

const PublicLayout = ({ children, bgImg, transparent = false }: Props) => (
  <div className='public-layout' style={{ backgroundImage: `url(${bgImg})` ,position:'fixed'}}>
    <div className={`content-box ${transparent ? 'transparent' : null}`} style={{background: 'rgba(255,255,255,0.5)'}}>
      <div className='content-header'>
        <Logo src={LogoSvg} />
      </div>
      <div className='content-body' style={{flexGrow: 0.4}}>{children}</div>
    </div>
  </div>
);

export default PublicLayout;
