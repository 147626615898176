
var serverUrl = "http://localhost:3010";
console.log("the environment is", process.env);
console.log("AMPLIFY Env is", process.env.REACT_APP_ENV);


if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV == "qa"){
  serverUrl = "https://odoomgmtdata.digitalglyde.com";
}
if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV == "prod"){
  serverUrl = "https://empappdata.wss-corp.com";
}
// var
// let appVersion = ""
// if (process.env.NODE_ENV === 'production') {
//     serverUrl = '';
// }
console.log("final setting for serverUrl", serverUrl);

export const settings = {
//   env: process.env.NODE_ENV,
  serverUrl,
//   appVersion
};

export default settings;
